@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Raleway-bold";
    src: url(./assets/fonts/Raleway-Bold.ttf);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Raleway-light";
    src: url(./assets/fonts/Raleway-Light.ttf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    font-family: 'Cairo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
*{
    scroll-behavior: smooth;
}
.brand-card {
    width: 20%;
    height: 300px;
    min-width: 300px;
    border-radius: 18px;
    margin: 1rem;
}

section #hero {
    height: 100vh;
    overflow: hidden !important;
    margin-top: 2rem;
}

.social-media-icons {
    width: 10rem;
    height: 10rem;
    padding-top: 1rem;
}

@media screen and (min-width: 1024px) {
    .services-container {
        background-color: rgba(0, 0, 0, 0);
    }
}
@media screen and (max-width: 1024px) {
    .services-container {
        background-image: url("./assets/imgs/assets/kryolan.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        background-color: rgba(0, 0, 0, 0.7);
    }

}
@media screen and (max-width: 450px) {
    #hero{
        height: auto;
        overflow: hidden;
    }

}
